import {graphql, useStaticQuery} from 'gatsby';
import {Category} from './useLatestPosts';

const LATEST_CATEGORIES_QUERY = graphql`
  query LATEST_CATEGORIES_QUERY {
    cms {
      latestCategories {
        name
        header
        subheader
        slug
      }
    }
  }
`;

export type LatestCategoriesData = Category[] | null;

export default function useLatestCategories(): LatestCategoriesData {
  const data = useStaticQuery(LATEST_CATEGORIES_QUERY);
  if (
    !data ||
    !data.cms ||
    !data.cms.latestCategories ||
    data.cms.latestCategories.length === 0
  ) {
    return null;
  }
  return data.cms.latestCategories;
}
